<template>
  <v-container>
    <certificate-dialog />
    <v-card class="shadow-1 pa-10">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <download-excel
          :data="searchResult"
          :fields="exportDataFields"
          :meta="[]"
          name="download.xls"
        >
          <app-button
            secondary
            :disabled="searchResult.length === 0"
            class="mt-4 mt-md-0"
          >
            Export results
          </app-button>
        </download-excel>
      </v-card-title>

      <unit-filter />

      <v-data-table
        :headers="tableHeaders"
        :items="projectsFilteredByUnit(projects)"
        :search="search"
        :custom-filter="customFilter"
        :no-data-text="noData"
        :no-results-text="noSearchResultMessage"
        :custom-sort="customSort"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Projects per page:',
          itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
        }"
      >
        <template
          v-if="isLoading"
          #body
        >
          <table-skeleton-loader
            :headers="tableHeaders.filter(h => shouldRenderColumn(null, h))"
          />
        </template>
        <template
          v-else
          #item="{ item }"
        >
          <tr>
            <template v-for="header in tableHeaders">
              <td
                v-if="shouldRenderColumn(item, header)"
                :key="header.value"
                :class="getClassForColumn(item, header)"
              >
                {{ getValueForColumn(item, header) }}
              </td>
            </template>
            <td style="text-align: right">
              <v-menu
                bottom
                left
                min-width="180"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="option in filterMenuOptions(item.phase.fullTitle)"
                    :key="option.name"
                    @click="menuActions(option.name, item)"
                  >
                    <template v-if="option.name === 'Notes'">
                      <menu-counter
                        label="Notes"
                        :amount="item.notes.length"
                      />
                    </template>
                    <template v-else-if="option.name === 'Comments'">
                      <menu-counter
                        label="Comments"
                        :amount="getAmountComments(item.id)"
                      />
                    </template>
                    <template v-else-if="option.name === 'Create Certificate'">
                      <create-certificate-option
                        :project="item"
                      />
                    </template>
                    <template v-else>
                      <v-list-item-title>{{ option.name }}</v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { ALUMNI } from '@kickbox/common-util';
import { TableSkeletonLoader } from '@kickbox/common-admin';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import searchFiltersMixin from '@/mixins/searchFiltersMixin';
import { config } from '@/utils';
import UnitFilter from './UnitFilter';
import CertificateDialog from './CertificateDialog';
import CreateCertificateOption from './CreateCertificateOption';
import MenuCounter from '@/components/widgets/MenuCounter';

export default {
  components: {
    UnitFilter,
    TableSkeletonLoader,
    CertificateDialog,
    CreateCertificateOption,
    MenuCounter
  },
  mixins: [searchFiltersMixin],

  props: {
    projects: {
      type: Array,
      required: true
    },
    customMenuOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    customMenuOptionCb: {
      type: Function,
      required: false,
      default: null
    },
    customHeaders: {
      type: Array,
      required: false,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      menuOptions: this.customMenuOptions.length
        ? this.customMenuOptions
        : [
          { name: 'View' },
          { name: 'Edit' },
          { name: 'Mark as alumni' },
          { name: 'Notes' },
          { name: 'Comments' },
          { name: 'Create Certificate' }
        ],
      customConfigByColumn: {
        activeTime: (item, column) => `${this.getInitialValue(item, column) || 0} ${this.getInitialValue(item, column) === 1 ? 'day' : 'days'}`,
        notes: (item, column) => (this.getInitialValue(item, column) || []).length || 0,
        campaign: (item, column) => (this.getInitialValue(item, column) || {}).title || 'no campaign',
        phase: (item, column) => (this.getInitialValue(item, column) ? this.getInitialValue(item, column).fullTitle : ''),
        creator: (item, column) => (this.getInitialValue(item, column) ? this.getInitialValue(item, column).name : ''),
        projectUnit: (item, column) => (this.getInitialValue(item, column) ? this.getInitialValue(item, column).name : '')
      },
      customConfigByType: {
        tags: (item, column) => (this.getInitialValue(item, column) || []).map((_) => _.name).join(', '),
        date: (value) => moment(value).format('MMM DD, YYYY')
      },
      ALUMNI,
      featureNames: FEATURE_NAME
    };
  },
  computed: {
    ...mapGetters(['activeTimes', 'company', 'commentsMap']),
    tableHeaders() {
      const headers = [
        { text: 'Title', value: 'title', align: 'left', width: 140 },
        { text: 'Creator', value: 'creator', align: 'left', width: 150 },
        { text: 'Phase', value: 'phase', align: 'left', width: 160 },
        { text: 'Active Time', value: 'activeTime', align: 'left', width: 130 },
        { text: 'Coaches', value: 'coachNames', width: 110 },
        { text: 'Project Unit', value: 'projectUnit', align: 'left', width: 130 },
        { sortable: false }
      ];

      if (this.company.features[FEATURE_NAME.COINS]) {
        headers.splice(4, 0, {
          text: 'Coins',
          value: 'coinBalance',
          align: 'left',
          width: 90
        });
      }

      if (this.company.features[FEATURE_NAME.CAMPAIGNS]) {
        headers.splice(headers.length - 1, 0, {
          text: 'Campaign',
          value: 'campaign',
          align: 'left',
          width: 130
        });
      }

      return this.customHeaders.length ? this.customHeaders : headers;
    },
    noData() {
      return this.isLoading ? 'Loading projects...' : 'No projects.';
    },
    exportDataFields() {
      return this.tableHeaders
        .filter((header) => !!header.text)
        .reduce((acc, header) => {
          if (this.customConfigByColumn.hasOwnProperty(header.value) || header.type) {
            let callback;
            if (this.customConfigByColumn.hasOwnProperty(header.value)) {
              callback = (value) => this.customConfigByColumn[header.value](value);
            } else {
              callback = (value) => this.customConfigByType[header.type](value);
            }
            acc[header.text] = {
              field: [header.value],
              callback
            };
          } else {
            acc[header.text] = header.value;
          }
          return acc;
        }, {});
    },
    searchResult() {
      return this.projectsFilteredByUnit(this.projects)
        .filter((p) => this.customFilter(null, this.search, p));
    }
  },
  methods: {
    filterMenuOptions(phaseFullTitle) {
      return this.menuOptions
        .filter((option) => !(option.name === 'Mark as alumni' && phaseFullTitle === ALUMNI.title))
        .filter((option) => !(option.name === 'Create Certificate' && this.company.airtableData && !this.company.airtableData.enabled));
    },
    shouldRenderColumn(item, column) {
      const { value } = column;
      return value && value.length > 0;
    },
    getInitialValue(item, column = false) {
      if (!column) {
        return item;
      }
      return item && item.hasOwnProperty(column.value) ? item[column.value] : null;
    },
    getValueForColumn(item, column) {
      const { type, value } = column;
      let initialValue = this.getInitialValue(item, column);

      if (type && this.customConfigByType.hasOwnProperty(type)) {
        const unformatedValue = item[column.value];
        initialValue = this.customConfigByType[type](unformatedValue);
      }

      if (value && this.customConfigByColumn.hasOwnProperty(value)) {
        initialValue = this.customConfigByColumn[value](item, column);
      }
      return initialValue;
    },

    getClassForColumn(item, column) {
      const { value } = column;

      const customConfigByColumn = {
        activeTime: () => (item.activeTime > 270 && !item.isAlumni ? 'timeInRed' : ''),
        campaign: () => 'campaign'
      };

      if (value && customConfigByColumn.hasOwnProperty(value)) {
        return customConfigByColumn[value]();
      }

      return '';
    },
    menuActions(option, project) {
      if (this.customMenuOptionCb) {
        this.customMenuOptionCb(option, project);
        return;
      }

      switch (option) {
        case 'View':
          window.open(`${config.portalUrl}/#/project/${project.id}`, '_blank');
          break;
        case 'Edit':
          this.$router.push({
            name: 'AdminManageProjectEdit',
            params: { id: project.id }
          });
          break;
        case 'Mark as alumni':
          this.$router.push({
            name: 'AdminManageProjectEdit',
            params: { id: project.id, alumni: 'alumni' }
          });
          break;
        case 'Notes':
          this.$router.push({
            name: 'AdminManageProjectNotes',
            params: { id: project.id }
          });
          break;
        case 'Comments':
          this.$router.push({
            name: 'AdminManageProjectComments',
            params: { projectId: project.id }
          });
          break;
        case 'Create Certificate':
          if (project.creator.active) {
            this.$store.commit('setProjectCertificate', {
              phaseFullTitle: project.phase.title,
              projectId: project.id
            });
          }
          break;
        default:
          break;
      }
    },
    customSort(items, sortProp, isDesc) {
      items.sort((self, other) => {
        if (sortProp.includes('phase')) {
          return self.phase.fullTitle.localeCompare(other.phase.fullTitle);
        }
        if (sortProp.includes('activeTime')) {
          if (self.activeTime === other.activeTime) {
            return 0;
          }
          return self.activeTime > other.activeTime ? -1 : 1;
        }
        if (sortProp.includes('projectUnit')) {
          const selfUnit = self.projectUnit ? self.projectUnit.name : '';
          const otherUnit = other.projectUnit ? other.projectUnit.name : '';
          if (selfUnit === otherUnit) {
            return 0;
          }
          return selfUnit > otherUnit ? -1 : 1;
        }
        if (self[sortProp[0]] === other[sortProp[0]]) {
          return 0;
        }
        return self[sortProp[0]] < other[sortProp[0]] ? -1 : 1;
      });
      return isDesc[0] ? items.reverse() : items;
    },
    customFilter(value, search, item) {
      let match = false;
      [
        ...this.tableHeaders,
        { text: 'Tags', value: 'tags' },
        { text: 'Cross-corporate tags', value: 'crossCorporateTags', type: 'tags' },
      ].filter((_) => _.hasOwnProperty('value')).forEach((column) => {
        const cellValue = this.getValueForColumn(item, column);
        if (!match) {
          match = String((cellValue || '')).toLowerCase().includes(search.toLowerCase());
        }
      });
      return match && this.unitFilter(item);
    },
    getAmountComments(projectId) {
      return this.commentsMap.get(projectId) || 0;
    }
  }
};
</script>

<style scoped>
  .list__tile__title {
    text-align: center;
  }

  table.table tbody td:last-of-type {
    padding: 0;
  }

  .list {
    min-width: 80px;
  }

  .timeInRed {
    color: red;
  }

  .campaign {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }

  .certificate-disabled {
    color: grey;
    cursor: initial;
  }
</style>
