var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('certificate-dialog'),_c('v-card',{staticClass:"shadow-1 pa-10"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.searchResult,"fields":_vm.exportDataFields,"meta":[],"name":"download.xls"}},[_c('app-button',{staticClass:"mt-4 mt-md-0",attrs:{"secondary":"","disabled":_vm.searchResult.length === 0}},[_vm._v(" Export results ")])],1)],1),_c('unit-filter'),_c('v-data-table',{staticClass:"table-container",attrs:{"headers":_vm.tableHeaders,"items":_vm.projectsFilteredByUnit(_vm.projects),"search":_vm.search,"custom-filter":_vm.customFilter,"no-data-text":_vm.noData,"no-results-text":_vm.noSearchResultMessage,"custom-sort":_vm.customSort,"footer-props":{
        itemsPerPageText: 'Projects per page:',
        itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
      }},scopedSlots:_vm._u([(_vm.isLoading)?{key:"body",fn:function(){return [_c('table-skeleton-loader',{attrs:{"headers":_vm.tableHeaders.filter(function (h) { return _vm.shouldRenderColumn(null, h); })}})]},proxy:true}:{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_vm._l((_vm.tableHeaders),function(header){return [(_vm.shouldRenderColumn(item, header))?_c('td',{key:header.value,class:_vm.getClassForColumn(item, header)},[_vm._v(" "+_vm._s(_vm.getValueForColumn(item, header))+" ")]):_vm._e()]}),_c('td',{staticStyle:{"text-align":"right"}},[_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.filterMenuOptions(item.phase.fullTitle)),function(option){return _c('v-list-item',{key:option.name,on:{"click":function($event){return _vm.menuActions(option.name, item)}}},[(option.name === 'Notes')?[_c('menu-counter',{attrs:{"label":"Notes","amount":item.notes.length}})]:(option.name === 'Comments')?[_c('menu-counter',{attrs:{"label":"Comments","amount":_vm.getAmountComments(item.id)}})]:(option.name === 'Create Certificate')?[_c('create-certificate-option',{attrs:{"project":item}})]:[_c('v-list-item-title',[_vm._v(_vm._s(option.name))])]],2)}),1)],1)],1)],2)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }