<template>
  <v-card-title>
    <v-row
      v-if="allUnits.length > 1 && company.features[featureNames.MULTI_ENTITY]"
      class="align-center"
      no-gutters
    >
      <v-col
        cols="12"
        md="2"
        lg="1"
        class="flex-grow-1 flex-shrink-0 text-no-wrap body-2"
      >
        Unit Filters:
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="11"
        class="flex-grow-0 flex-shrink-1"
      >
        <v-combobox
          v-model="units"
          :items="allUnits"
          chips
          clearable
          multiple
          item-text="name"
          item-value="name"
          @change="onSelectionChange"
        >
          <template #selection="data">
            <v-chip
              input-value="data.selected"
              close
              class="unit-chip primary--text"
              close-icon="close"
              @click:close="remove(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </v-card-title>
</template>

<script>
import { mapGetters } from 'vuex';
import { CompanyService, UnitService } from '@kickbox/common-admin';
import Parse from '@kickbox/common-util/src/parse';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';

export default {
  data() {
    return {
      search: '',
      units: [],
      allUnits: [],
      featureNames: FEATURE_NAME
    };
  },
  computed: {
    ...mapGetters(['company']),
  },
  watch: {
    units() {
      this.$store.commit('setAdminUnits', this.units);
    }
  },
  async created() {
    const company = this.company.parseObject;
    const adminUnits = await UnitService.getCurrentUserUnits(company);

    const userEmail = Parse.User.current().get('email');
    const { unitAdmins } = this.company;

    if (unitAdmins.includes(userEmail)) {
      this.allUnits = adminUnits;
    } else {
      this.allUnits = await CompanyService.getUnitsByCompany(company) || [];
      if (adminUnits) this.units = adminUnits;
    }
  },
  methods: {
    remove(item) {
      this.units.splice(this.units.indexOf(item), 1);
    },
    onSelectionChange() {
      if (this.units && this.units.length) {
        this.units.forEach((item, index) => {
          const itemInSource = typeof item === 'string' ? this.allUnits.find((_) => _.name === item) : item;

          if (typeof item === 'string') {
            this.units.splice(index, 1);
            if (itemInSource) this.units.push(itemInSource);
          }
        });
      }
    }
  }
};
</script>
