import { mapGetters } from 'vuex';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';

export default {
  data() {
    return {
      search: '',
    };
  },
  methods: {
    unitFilter(project) {
      if (!this.adminUnits.length || !this.company.features[FEATURE_NAME.MULTI_ENTITY]) {
        return true;
      }
      return this.adminUnits.some((u) => project.projectUnit
      && u.id === project.projectUnit.id);
    },
    projectsFilteredByUnit(projects) {
      if (!this.company.features[FEATURE_NAME.MULTI_ENTITY]) return projects;
      return projects
        .filter((p) => !this.adminUnits.length
          || this.adminUnits.some((u) => p.projectUnit && u.id === p.projectUnit.id));
    },
  },
  computed: {
    ...mapGetters(['adminUnits', 'company']),
    noSearchResultMessage() {
      if ((!this.adminUnits.length && this.search && !this.searchResult.length)
        || !this.company.features[FEATURE_NAME.MULTI_ENTITY]) {
        return 'Your search did not match any projects. Try to use different keywords.';
      }
      if (this.adminUnits.length && !this.search && !this.searchResult.length) {
        return 'Your search for selected units did not match any projects. Try to change filters.';
      }
      if (this.adminUnits.length && this.search && !this.searchResult.length) {
        return 'Your search for the selected units and the entered text did not match any projects. Try to change filters or use different keywords.';
      }
      return '';
    },
  }
};
