<template>
  <v-list-item-title>
    <div class="d-flex align-center">
      {{ label }}
      <v-chip
        color="primary"
        class="white--text tag my-0 ml-auto"
        small
      >
        {{ amount }}
      </v-chip>
    </div>
  </v-list-item-title>
</template>

<script>
export default {
  name: 'MenuCounter',
  props: {
    label: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped>
.tag {
  padding: 0 10px;
}
</style>
